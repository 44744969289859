.set-dnd-revision-css {
  width: 100%;
}
.set-css-card-revision-css {
  background: #f2f2f2;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 62px;
  width: 100%;
  padding: 11px 0px 0px 23px;
  display: flex;
}
.set-font-revision-css {
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 2px;
  color: rgb(38, 42, 45);
  font-weight: 700;
}
.row-gap-css-revision-css {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.set-lay-out-delete-revision-css {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: -9px;
}
.set-font-delete-revision-css {
  font-size: 20px;
}
.set-point-css-revision {
  cursor: pointer;
}
.hover-color-css-revision:hover {
  color: white;
  background-color: #282f6a;
  border-radius: 6px;
}
.p-toast-top-right {
  z-index: 100000;
}
.hover-card-in-revision-css:hover {
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 25%);
  cursor: pointer;
}
.ss {
  display: flex;
}
.ee {
  margin-top: 10px;
  margin-left: -20px;
}
