
.form-logic-conditions {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

@media only screen and (max-width: 1200px) {
    .form-logic-conditions {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.edit-conditions-form-logic {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    .edit-conditions-form-logic {
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-end;
    }

    .edit-conditions-form-logic > *:first-child {
        margin-left: 7.5rem;
    }
}