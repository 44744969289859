.select-period-container {
  display: flex;
}
.select-period-container div:nth-child(1) {
  width: 150px;
}
.delegater-screen .route-text-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
}
.delegater-screen .route-text-container .button-delegate-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}
.delegater-screen .route-text-container button {
  height: 32px;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  padding: 0 24px;
  grid-column-gap: 5px;
  column-gap: 5px;
  font-size: 1em;
  font-weight: 300;
  transition-duration: 0.25s;
}
.delegater-screen .route-text-container .button-delete {
  color: #ff2626;
}
.delegater-screen .route-text-container .button-submit {
  color: #282f6a;
}
.delegater-screen .route-text-container .button-update {
  color: #fca71b;
}
.delegater-screen .route-text-container .button-delete:hover {
  color: #fff;
  background-color: #ff2626;
  transition-duration: 0.25s;
}
.delegater-screen .route-text-container .button-submit:hover {
  color: #fff;
  background-color: #282f6a;
  transition-duration: 0.25s;
}
.delegater-screen .route-text-container .button-update:hover {
  color: #fff;
  background-color: #fca71b;
  transition-duration: 0.25s;
}
.custom-dataTable .p-column-filter .p-inputtext {
  min-width: 200px;
}