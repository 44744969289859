.wrapFreehandBtns button {
  margin-right: 10px;
  background: #3f51b5;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px #ccc;
  border-radius: 5px;
}

.wrapper-bottom {
  text-align: center;
}

.wrapper-bottom button {
  margin-right: 10px;
  background: #3f51b5;
  padding: 5px 10px;
  width: 100px;
  color: white;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 1px #ccc;
}

.react-pdf__Page {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
}
.react-pdf__Page__textContent {
  /* border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px; */
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  /* margin: auto; */
}

.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.crosshair {
  cursor: crosshair;
}

.drawing {
  z-index: 1;
  position: absolute !important;
  top: 0;
  left: 0;
}

.path {
  fill: none;
  stroke-width: 1px;
  stroke: rgb(7, 7, 249);
  /* stroke: red; */
  stroke-linejoin: round;
  stroke-linecap: round;
}

.drawing {
  width: 100%;
  height: 100%;
}
