.header-text .p-dialog-header {
  padding: 0.5rem;
}
.Validation {
  border-color: red;
}

.p-dialog .p-dialog-content {
  padding: 0 18px 2rem 18px;
}

@media screen and (min-width: 320) and (max-width: 425) {
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  white-space: normal;
  word-wrap: break-word;
}
