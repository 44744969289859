.App {
  width: 100vw;
  height: 100vh;
  background-color: white;
}
.timeout-screen {
  background-color: rgba(0, 0, 0, 0.329);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timeout-screen .timeout-row {
  position: relative;
  width: 50vw;
  max-width: 330px;
}
.timeout-screen .timeout-header {
  color: #282f6a;
  font-size: 46px;
  margin-bottom: 10px;
}
.timeout-screen .timeout-row .dot {
  position: absolute;
  top: 40%;
  left: 50%;
  font-size: 36px;
}
.timeout-screen p {
  color: white;
}
.time-timeout .dot {
  font-size: 46px;
  color: white;
}
.timeout-screen .time-timeout {
  font-size: 46px;
  color: white;
  text-align: center;
}
.timeout-screen .time-timeout-text {
  color: #e0e0e0;
  font-size: 24px;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .sidebar-create-element {
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
    width: 100%;
  }
  .Sidebar-create-control {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 39.938rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.625rem;
  }
  .create-control {
    width: 70.20833333333333vw;
    height: 43.5vw;
    border-radius: 16px;
  }
  .set-layout-check-box-create-control {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .sidebar-create-element {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
    row-gap: 0.625rem;
    column-gap: 0.425rem;
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .create-control {
    width: 80vw;
    border-radius: 16px;
  }
  .Sidebar-create-control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
    row-gap: 0.625rem;
    column-gap: 0.425rem;
    width: 100%;
  }

  .ant-col-offset-1 {
    /* margin-left: 0px; */
  }
  .set-layout-control-edit-and-icon {
    padding-left: 0px;
    padding-right: 0px;
  }
  .confirm-container-Create-Email {
    flex-direction: column;
  }

  .set-layout-check-box-create-control {
    justify-content: center;
  }
  .footer-dialog-css {
    position: unset;
    justify-content: flex-end;
    margin-right: 1rem;
  }
}
/* .Navbar {
  color: #e6e6e6;
  padding: 10px;
  background: #4167b2;
}

.NavbarLayer {
  color: #e6e6e6;
  padding: 10px;
  background: #2b8859;
}

body {
  margin: 0 !important;
  padding: 0;
}

.Sidebar {
  height: 100%;
  width: 100%;
  background-color: aqua;
}

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
}

.SidebarComponent {
  top: 196px;
  left: 40px;
  width: 246px;
  height: 40px;
  background: #e6e6e6 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
}

.SidebarList {
  height: 50px;
  width: 100%;
}

.row {
  padding-top: 10px;
} */
