.core-control-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.core-control-container .delete {
  display: none;
}
.invalid {
  border: 1px solid red;
  width: 100% !important;
}
.invalid-r .p-radiobutton-box {
  border: 2px solid red;
}
.invalid-cb .p-checkbox-box {
  border: 2px solid red;
}
.border-shadow-core-control {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000001a;
  opacity: 1;
  border-radius: 12px;
  padding-bottom: 20px;
  min-height: 200px;
}

.core-padding {
  padding: 30px;
}
.core-display-padding-margin {
  display: flex;
  padding: 30px;
  margin-top: -15px;
}
.padding-controller {
  padding: 0 30px;
}
.padding-table-controller {
  padding: 0 30px;
  margin-top: 10px;
}
.padding-header-controller {
  padding-left: 0 30px;
}
.loading-item {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0000001f;
  z-index: 1;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .padding-controller:nth-child(4) {
  padding-right: 40px;
} */
.form-core-control-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000001a;
  opacity: 1;
  border-radius: 12px;
  padding-bottom: 20px;
}
.form-core-control-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell {
  /* display: flex; */
}
.form-core-control-container
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input,
.ant-picker {
  border-radius: 6px !important;
}
