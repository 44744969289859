.border-icon-manual {
    border: 1px solid red;
    height: 43.14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43.14px;
    border-radius: 6px;
    background-color: red;
    cursor: pointer;
    color: #fff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 38px;
    margin-left: 5px; 
    transition-duration: 0.25s;
  }
 .border-icon-manual:hover {
    background-color: darkred;
    border-color: darkred;
    height: 38px;
    transition-duration: 0.25s;
  }