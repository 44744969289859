.file-input-container{
    display: flex;
    flex-direction: column;
}
.file-input-container .file-require{
    align-self: flex-end;
    color: red;
}
.form-core-control-container .table-cell{
    /* display: flex; */
}
.form-core-control-container .checkbox-group-container{
    display: flex;
    flex-direction: column;
}

.text-right input{
    text-align: right;
}

.text-left input{
    text-align: left;
}

/* .ant-input-number input{
    border-radius: 6px ;
} */
.ant-input-number-group > .ant-input-number:last-child, .ant-input-number-group-addon:last-child{
    border-radius: none;
}

.ant-input-number-group > .ant-input-number:last-child, .ant-input-number-group-addon:last-child
{
    border-radius: none;
}

.input-component{
    border-radius: 6px !important;
}

.symbol-after {
    border-radius: 6px 0px 0px 6px !important;
}

.symbol-before {
    border-radius: 0px 6px 6px 0px !important;
}

.symbol-after .ant-input-number-group-addon{
    border-radius: 0px 6px 6px 0px !important;
}
.input-component-container .ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-checked .ant-checkbox-inner{
    background-color:#f8a51c;
    background:#f8a51c
}
.input-component-container .ant-checkbox-checked .ant-checkbox-inner, .ant-radio-checked .ant-radio-inner, .ant-checkbox-checked .ant-checkbox-inner{
    border-color:#f8a51c
}
.input-component-container .ant-checkbox-checked::after, .ant-radio-checked::after, .ant-checkbox-checked::after{
    border:1px solid #f8a51c
}
.input-component-container .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-radio:hover .ant-radio-inner{
    border-color:#f8a51c
}
.input-component-container .ant-radio-inner::after, .ant-radio-inner::after{
    background-color : #f8a51c
}
.input-component-container .ant-input-number:hover, .ant-input-number-focused, .ant-picker:hover, .ant-picker-focused, .ant-input:hover, .ant-input:focus, .ant-input-focused, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-radio-input:focus + .ant-radio-inner, .ant-input-number:focus{
    border-color:#f8a51c

}
.input-component-container .ant-radio-input:focus + .ant-radio-inner, .ant-input-number:focus, .ant-input-number-focused, .ant-radio-input:focus + .ant-radio-inner{
    box-shadow: 0 0 0 3px #fff5e4;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper{
    margin: 0;
}