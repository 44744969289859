.pdf-preview-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #e6e6e6;
  /* align-items: center; */
  justify-content: flex-start;
  
}
 .page {
  display: block;
  margin-bottom: -1rem;
}
/*.page > canvas {
  margin: 1rem;
  box-shadow: 2px 2px 4px #a0a0a0;
} */


/* Zoom Controls */
.zoom-controls {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 10px;
}

.zoom-controls button {
  padding: 8px 16px;
  background-color: rgb(40, 47, 106);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.zoom-controls button:hover {
  background-color: #0056b3;
}

/* PDF Preview Container */
.pdf-preview-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
  max-height: 80vh; /* Adjust height to prevent overflow */
  overflow-y: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Canvas Pages */
.page canvas {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-radius: 5px; */
  /* max-width: 100%; */
  padding: 0px 30px 0px 30px;
  height: auto;
}
@media print {
  .pdf-preview-container {
    display: none;
  }
}
