.package-loading{
    background-color: #282f6a;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
}
.package-loading p{
    margin: 0;
}
.package-loading .wolf-logo{
    width: 450px;
    margin-bottom: 24px;
}
.package-loading .progressbar-instaling{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}
.package-loading .progressbar-instaling .text-show{
    font-size: 1.5rem;
    color: white;
}
.fade-enter .package-loading {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-enter-active .package-loading {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit .package-loading {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active .package-loading {
    opacity: 0;
    transform: translateX(100%);
  }
  .fade-enter-active .package-loading,
  .fade-exit-active .package-loading {
    transition: opacity 500ms, transform 500ms;
  }
  /* .code-box-shape{
    background-color: blue;
  } */
  /* .code-box-shape{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    background: #1890ff;
    z-index: 9999;


  } */
  .slide-enter {
    opacity: 0;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .slide-appear {
    opacity: 0;

    animation-duration: 1s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .slide-leave {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  
  .slide-enter.slide-enter-active {
    animation-name: slideIn;
    animation-play-state: running;
  }
  
  .slide-appear.slide-appear-active {
    animation-name: slideIn;
    animation-play-state: running;
  }
  
  .slide-leave.slide-leave-active {
    animation-name: slideOut;
    animation-play-state: running;
  }
  
  @keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    30%{
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);

    }
  }
  
  @keyframes slideOut {
    0% {
      /* opacity: 1; */
      transform: translateY(0px);
    }
    100% {
      /* opacity: 0; */
      transform: translateY(100%);

    }
  }