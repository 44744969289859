.set-button-css-ref {
    background: red;
    color: white;
    border-color: white;
}
.set-button-css-ref:hover {
    background: white !important;
    color: red !important;
    border-color: #c02929 !important;
}
