.forget-password-container {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  position: relative;
  max-width: 800px;
}
.back-button{
  top: 20px;
  left: 20px;
  position: absolute;
  cursor: pointer;
}
.forget-password-container img {
  width: 300px;
  height: auto;
}
.forget-password-container .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forget-password-container .text-container p {
  margin: 0;
  text-align: center;
}
.forget-password-container .text-container .text-header {
  font-size: 22px;
  color: #282f6a;
}
.forget-password-container .text-container .text-detail {
  font-size: 17px;
  color: #262a2d;
}
.forget-password-container .input-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.forget-password-container .input-container p {
  margin: 0;
  color: #262a2d;
}
.forget-password-container .input-container .input-class {
  width: 390px !important;
  border-radius: 6px;
  outline: none;
  border: 1px solid #b4b4b4;
  padding: 7px 20px;
  margin-bottom: 10px;
}
.forget-password-container .input-container .text-error {
  position: absolute;
  margin: 0;
  color: red;
  bottom: -20px;
}
.forget-password-container .input-container .invalid {
  border: 1px solid red;
}
.forget-password-container .input-container .input-class2 {
  width: 100%;
  border-radius: 6px;
  outline: none;
  border: 1px solid #b4b4b4;
  padding: 7px 20px;
}
.forget-password-container .button-confirm,
.button-confirm-2 {
  border: none;
  border-radius: 6px;
  background-color: #f8a51c;
  font-size: 18px;
  color: #fff;
  padding: 12px 120px;
}
.forget-password-container .button-confirm-2 {
  margin-top: 20px;
}
.without-nav {
  height: 100vh;
}
.forget-password-container > .text-error {
  color: red;
  font-size: 18px;
  margin: 0;
}
