*:not(.grid-item, .pi) {
  /* font-family: "Kanit", sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
}

.txt-template-name-custom-style {
  font-size: 13px !important;
  color: #b4b4b4 !important;
}

.ant-table-filter-dropdown {
  max-width: 400px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.worklist-container .header-container .button-container span {
  width: 100%;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
