.btn-pi-sync .btn-btn-sync {
    height: 32px !important;
    padding: 5px 8px;
}

.btn-pi-sync .pi.pi-sync {
    font-size: 14px;
}

.btn-pi-sync .p-button-label.p-c {
    font-size: 14px;
}