.show-tablet-navbar-request-min-1024-create-control {
    background-color: #fff;
    /* position: sticky; */
    top: 11rem;
    /* z-index: 999; */
    padding: 0 39px 10px;
  }

@media screen and (min-width:468px) and (max-width:575.9px){
    .sidebar-style-display .col-12 {
        flex: 0 0 auto;
        width: 50%;
    }
}
@media screen and (min-width:992px) and (max-width:1024px){
    .sidebar-style-display .col-lg-12 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
}

@media screen and (min-width: 1025px) {
    .show-tablet-navbar-request-min-1024-create-control {
        display: none !important;
    }
}

@media screen and (max-width: 1025px) {
    .set-min-width-td{
        display: none !important;
    }
}
.set-css-images {
    transform: none !important;
}
.set-text-header-on-edit-form{
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 2px;
    color: rgb(124, 124, 124);
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}