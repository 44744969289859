  .wrapFreehandBtns {
    display: flex;
    justify-content: center;
  }
  
  #mydiv {
    position: absolute;
    z-index: 9;
    background-color: #f1f1f1;
    text-align: center;
    border: 1px solid #d3d3d3;
  }
  
  #mydivheader {
    padding: 10px;
    cursor: move;
    z-index: 10;
    background-color: #2196F3;
    color: #fff;
  }
  
  .react-draggable, .cursor {
    cursor: move;
  }
  .no-cursor {
    cursor: auto;
  }
  .cursor-y {
    cursor: ns-resize;
  }
  .cursor-x {
    cursor: ew-resize;
  }
  
  .react-draggable strong {
    background: #ddd;
    border: 1px solid #999;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    padding: 3px 5px;
    text-align: center;
  }
  
  .box {
    background: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    width: 180px;
    height: 180px;
    margin: 10px;
    padding: 10px;
    float: left;
  }
  
  .rem-position-fix {
    position: static !important;
  }