.react-grid-layout {
  /* border: 1px solid black; */
}

.color-picker-popover .ant-popover-inner-content {
  padding: 0px;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.custom-resizeHandle {
  text-align: center;
  align-self: flex-end;
}

.editor-layout {
  min-width: 100%;
  display: flex;
  column-gap: 10px;
}
.sidebar {
  min-width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 300px);
}

.tool-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
}

.static-img {
  overflow: hidden;
  height: 100%;
}

.static-img .ant-upload-list {
  height: 100%;
}

.static-img .ant-upload {
  height: 100% !important;
  width: 100% !important;
}

.board {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  background-color: #f0f0f0;
  overflow: scroll;
  min-height: calc(100vh - 300px);
  transform-origin: top left; /* Set the origin for scaling */
  position: relative;
}

.custom-grid {
  text-align: center;
  position: relative;
}
.drag-handle {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -10px;
  top: -10px;
  background-color: #d3d3d3b3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
  border-radius: 6px;
}

.textbox {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.auto-size-text {
  flex: 1; /* Allow the text to expand to fill the parent container */
  overflow: hidden; /* Hide any overflow text */
  white-space: nowrap; /* Prevent the text from wrapping to the next line */
  text-overflow: ellipsis; /* Add ellipsis (...) if the text overflows */
}

.toolbar {
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 6px;
  position: relative;
}

.react-grid-item.react-grid-placeholder {
  background-color: rgb(39, 214, 161) !important;
  /* border-radius: 6px; */
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.zoom-container {
  overflow: hidden;
  width: 300px;
  height: 200px;
  border: 1px solid #ccc;
}

.zoomable-div {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.paper {
  background-color: #ffffff;
  background-image: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 14px,
      #ccc 14px,
      #ccc 15px
    ),
    repeating-linear-gradient(
      180deg,
      transparent,
      transparent 14px,
      #ccc 14px,
      #ccc 15px
    );
  background-origin: content-box;
  position: absolute;
  transform-origin: top left;
}

pre {
  height: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.main-layout {
  width: 100%;
}
.inner-layout {
  width: 100%;
  height: 100% !important;
  /* background-color: rgba(127, 255, 212, 0.334); */
}

.custom-grid .ant-tag {
  position: absolute;
  top: -15px;
  z-index: 1;
}
.custom-grid img {
  position: absolute;
  top: 0px;
  z-index: 1;
}

.red-line {
  border: 1px red dashed;
  position: absolute;
  width: 100%;
}

.zoom-panel {
  width: 100%;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 95%;
  display: flex;
  justify-content: end;
  color: rgba(40, 47, 106, 1);
}

.zoom-element-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}

.zoom-slider {
  width: 150px;
  height: 15px;
  background-color: rgba(40, 47, 106, 1);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.zoom-cursor {
  width: 5px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 50%;
}

.tabs-group {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -35px;
}

.tab-button {
  min-width: 100px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tab-button.active {
  background-color: rgba(241, 241, 241, 1) !important;
  border-bottom: 2px solid rgba(40, 47, 106, 1);
}

.tab-panel {
  height: 100%;
  padding: 10px;
}

.child-grid {
  /* background-color: #ffffff; */
  border-color: rgba(42, 128, 185, 1) !important;
  border-radius: 6px;
}

.child-grid > .react-resizable-handle {
  position: absolute;
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(42, 128, 185, 1) !important;
  -webkit-transform: unset !important;
  transform: unset !important;
  background-image: unset;
}

.child-grid > .react-resizable-handle-n {
  top: -3px !important;
  z-index: 1 !important;
  content: unset !important;
}
.child-grid > .react-resizable-handle-ne {
  top: -3px !important;
  right: -3px !important;
  content: unset !important;
  z-index: 1 !important;
}
.child-grid > .react-resizable-handle-nw {
  top: -3px !important;
  left: -3px !important;
  content: unset !important;
  z-index: 1 !important;
}
.child-grid > .react-resizable-handle-s {
  bottom: -3px !important;
  content: unset !important;
  z-index: 1 !important;
}
.child-grid > .react-resizable-handle-se {
  bottom: -3px !important;
  content: unset !important;
  right: -3px !important;
  z-index: 1 !important;
}
.child-grid > .react-resizable-handle-sw {
  bottom: -3px !important;
  left: -3px !important;
  content: unset !important;
  z-index: 1 !important;
}
.child-grid > .react-resizable-handle::after {
  content: unset !important;
}
.child-grid table,
.child-grid th,
.child-grid td {
  border: 1px solid black;
  border-collapse: collapse;
  height: 100%;
}
